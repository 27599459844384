<template>
  <div
    class="p-3 rounded-sm shadow-lg cursor-pointer ring-2 ring-gray-100"
    @click="showProfile(item)"
  >
    <div class="flex flex-col items-center justify-center">
      <div
        class="flex items-center justify-center w-full h-48 overflow-hidden rounded-sm "
      >
        <img
          v-if="item?.photo_url"
          :src="item?.photo_url"
          class="object-cover object-center w-full rounded-sm shadow-lg max-h-48"
        />
        <img
          v-else
          :src="defaultImage"
          class="object-cover object-center max-w-full max-h-48"
        />
      </div>
      <h2 class="w-full mt-3 text-2xl font-semibold text-center text-indigo-900 capitalize">
        {{ item.first_name }} {{ item.last_name }}
      </h2>
      <div>
        <vue3-star-ratings :showControl="false" :disableClick="true" v-model="rating" />
      </div>
      <h3 class="w-full mb-3 text-lg text-center">{{ item.bio }}</h3>
      <template v-if="item?.is_volunteer">
        <div class="flex justify-center w-full px-3 py-2 bg-gray-50">
          <div class="text-xl font-bold text-custom-green" v-if="item?.is_volunteer">Volunteer</div>
        </div>
      </template>
      <template v-else>
        <div class="flex justify-end w-full px-3 py-2 bg-gray-50 ">
          <span  class="text-xl font-bold text-gray-800 uppercase sr-only ">
            Rate
          </span>
          <span class="text-xl font-bold text-custom-green" v-if="item.hour_rate">${{ item.hour_rate }}/Hr</span>
        </div>
      </template>
      </div>
        <div class="flex items-center justify-start w-full px-3 pb-2 space-x-2 bg-white">
          <FontAwesomeIcon :icon="faMapMarkerAlt" class="text-custom-green"></FontAwesomeIcon><span> {{ item.city }}, {{ item.province }}</span>
        </div>
    </div>
</template>
<script>
import { ref } from '@vue/reactivity'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons'
import vue3StarRatings from 'vue3-star-ratings'
import { onMounted } from 'vue'

export default {
  components: {
    FontAwesomeIcon,
    vue3StarRatings
  },
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  setup (props) {
    const defaultImage = ref(require('@/assets/logo.png'))
    const router = useRouter()
    const store = useStore()
    // props.item.avg_rating
    const rating = ref(props.item.avg_rating ?? 0)
    console.log('rating', rating.value)
    const showProfile = async (user) => {
      console.log(user)
      await store.dispatch('data/setUserProfile', user)
      router.push({
        name: 'Profile'
      })
    }

    // const getRating = () => {
    //   const rate = Math.floor(Math.random() * 6)
    //   console.log('rating', rate)
    //   rating.value = rate
    // }

    onMounted(() => {
      // getRating()
    })
    return {
      showProfile,
      defaultImage,
      faMapMarkerAlt,
      rating
    }
  }
}
</script>
