<template>
  <div class="" >
    <div class="flex flex-col gap-10 md:flex-row">
      <div class="w-full space-y-5 md:w-1/2 lg:w-1/3">
        <div
          class="flex flex-col items-center justify-center px-4 py-6 bg-white rounded-tl-lg rounded-tr-lg shadow md:p-4 xl:py-8 xl:px-4 "
        >
          <h3 class="mb-5 text-2xl font-bold uppercase">
            Find Service Provider
          </h3>
          <!-- <div class="relative w-full mt-1 rounded-md shadow-sm">
            <input
              type="text"
              ref="searchInput"
              name="search"
              @input="filterCategory($event.target.value)"
              class="block w-full py-3 pr-10 text-lg border-0 shadow focus:border-b-1 focus:border-custom-green focus:ring-0 focus:shadow-lg ring-2 ring-gray-100"
              placeholder="Search service type"
            />
            <div
              class="absolute inset-y-0 right-0 flex items-center pr-3 cursor-pointer "
            >
              <FontAwesomeIcon
                class="w-5 h-5 text-custom-green"
                :icon="faSearch"
              />
            </div>
            <div
              class="absolute z-20 w-full p-2 shadow-lg bg-gray-50"
              v-if="autoCompleteRes.length"
            >
              <div v-for="(service, index) in autoCompleteRes" :key="index">
                <button
                  class="flex items-center justify-between w-full px-1 py-2 overflow-hidden break-all hover:text-custom-green md:text-lg"
                  :class="{
                    'text-custom-green': service.id == selectedCat,
                  }"
                  @click="searchProfile(service.id)"
                  :key="index"
                  type="button"
                >
                  <span class="text-left">{{ service.name }}</span>
                </button>
              </div>
              <button
                @click="resetSearch"
                class="w-full py-2 mt-3 text-center bg-gray-200"
              >
                Reset
              </button>
            </div>
          </div> -->
          <div class="flex w-full ">
            <VueMultiselect
              v-model="selected"
              @select="searchProfile($event)"
              :options="allCategories"
              @search-change="mapCategory"
              label="name"
              placeholder="Search Service type"
              track-by="name"
            >
          </VueMultiselect>
          </div>
        </div>
        <div
          class="flex flex-col items-center justify-center px-4 py-6 bg-white rounded-bl-lg rounded-br-lg shadow md:p-4 xl:py-8 xl:px-4"
        >
          <svg
            class="w-10 h-10 text-custom-green animate-spin"
            xmlns="http://www.w3.org/2000/svg"
            v-if="isLoading"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              class="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              stroke-width="4"
            ></circle>
            <path
              class="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            ></path>
          </svg>
          <!--  -->
          <div class="w-full">
            <div class="w-full p-2 mx-auto bg-white rounded-2xl">
              <template v-for="(services, index) in servicesList" :key="index">
                <Disclosure v-slot="{ open }">
                  <DisclosureButton
                    class="flex items-center justify-between w-full px-6 py-2 mt-5 font-semibold text-left text-gray-800 bg-white shadow-lg md:font-bold md:text-lg lg:text-xl ring-2 ring-gray-100 hover:bg-gray-100 focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75"
                  >
                    <span>{{ index }}</span>
                    <FontAwesomeIcon
                      :class="open ? 'transform rotate-180' : ''"
                      class="w-5 h-5 text-custom-green"
                      :icon="faAngleDown"
                    />
                  </DisclosureButton>
                  <DisclosurePanel
                    class="px-2 pt-4 pb-2 text-sm bg-gray-100 shadow-lg xl:px-4"
                  >
                    <div class="flex flex-col">
                      <button
                        class="flex items-center justify-between px-1 py-2 overflow-hidden md:text-lg overflow-ellipsis"
                        :class="{
                          'text-custom-green': service.id == selectedCat,
                        }"
                        @click="searchProfile(service)"
                        v-for="(service, index) in services"
                        :key="index"
                        type="button"
                      >
                        <span class="text-left">{{ service.name }}</span>
                        <font-awesome-icon
                          class="w-5 h-5 text-custom-green"
                          :icon="faAngleRight"
                        ></font-awesome-icon>
                      </button>
                    </div>
                  </DisclosurePanel>
                </Disclosure>
              </template>
            </div>
          </div>

          <!--  -->
        </div>
      </div>
      <div class="w-full p-6 bg-white rounded shadow md:w-2/3 lg:w-3/4">
        <h3 class="mb-5 text-2xl font-bold uppercase" v-if="searchResult.length">
          Total
          <span v-if="searchResult.length">{{ searchResult.length }}</span>
          result found
        </h3>
        <!--  -->
        <div class="grid grid-cols-1 gap-6 md:grid-cols-2 xl:grid-cols-3"  id="results">
          <svg
            class="w-10 h-10 text-custom-green animate-spin"
            xmlns="http://www.w3.org/2000/svg"
            v-if="isSearchLoading"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              class="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              stroke-width="4"
            ></circle>
            <path
              class="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            ></path>
          </svg>
          <template v-else>
            <SearchItem
              v-for="(item, index) in searchResult"
              :key="index"
              :item="item"
              :index="index"
            ></SearchItem>
            <div v-if="!searchResult.length">
              Please choose category to check profile
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { faAngleDown, faAngleRight, faSearch } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue'
import { ref } from '@vue/reactivity'
import { onMounted } from '@vue/runtime-core'
import _ from 'lodash'
import VueMultiselect from 'vue-multiselect'
import { useStore } from 'vuex'
import SearchItem from '../components/SearchItem.vue'

export default {
  components: {
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
    VueMultiselect,
    FontAwesomeIcon,
    SearchItem
  },
  setup () {
    const store = useStore()
    const profile = ref(null)
    const searchInput = ref(null)
    const selectedCat = ref(null)
    const servicesList = ref([])
    const searchResult = ref([])
    const autoCompleteRes = ref([])
    const isLoading = ref(true)
    const isSearchLoading = ref(false)
    const selected = ref(null)
    const allCategories = ref([])

    const searchProfile = async (item) => {
      selectedCat.value = item.id
      isSearchLoading.value = true
      await store.dispatch('data/profileSearch', selectedCat.value)
      searchResult.value = await store.state.data.searchProfiles
      isSearchLoading.value = false
      // servicesList.value = services
      allCategories.value = []
      isLoading.value = false
      document.getElementById('results').scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' })
      // console.log('scroll position', window)
      // console.log('scroll position', document.getElementById('results').offsetTop)
      // console.log('scroll position', document.getElementById('pageContent').offsetTop)
      // document.getElementById('pageContent').scrollTop = (parseInt(document.getElementById('results').offsetTop) + 200)
      // console.log('scroll position', (parseInt(document.getElementById('results').offsetTop) + 200))
    }
    onMounted(async () => {
      await store.dispatch('data/getServices')
      isLoading.value = false
      servicesList.value = await store.state.data.services
      autoCompleteRes.value = servicesList.value
      // mapCategory()
    })
    // for (const kk in elements) {
    //             if (Object.hasOwnProperty.call(elements, kk)) {
    //               const item = elements.value[key]
    //               console.log(item)
    //               allCategories.value.push(_.uniqBy(item, 'id'))
    //             }
    //           }
    const mapCategory = (services) => {
      let results = []
      for (const key in servicesList.value) {
        if (Object.hasOwnProperty.call(servicesList.value, key)) {
          const elements = servicesList.value[key]
          for (const key in elements) {
            if (Object.hasOwnProperty.call(elements, key)) {
              const item = elements[key]
              results = [...results, item]
            }
          }
        }
      }
      allCategories.value = _.uniqBy(results, 'id')
    }
    const filterCategory = (search) => {
      if (search === '') {
        autoCompleteRes.value = servicesList.value
        return true
      }
      const resultItems = []
      for (const key in servicesList) {
        if (Object.hasOwnProperty.call(servicesList, key)) {
          const items = servicesList[key]
          for (const key in items) {
            if (Object.hasOwnProperty.call(items, key)) {
              const elements = items[key]
              for (const key in elements) {
                if (Object.hasOwnProperty.call(elements, key)) {
                  const service = elements[key]
                  const results = _.includes(
                    service.name.toLowerCase(),
                    search.toLowerCase()
                  )
                  if (results) {
                    resultItems.push(service)
                  }
                }
              }
            }
          }
        }
      }

      console.log('resultItems', resultItems)
      return (autoCompleteRes.value = _.uniqBy(resultItems, 'id'))
    }
    const resetSearch = () => {
      autoCompleteRes.value = []
      searchInput.value.value = null
    }
    return {
      profile,
      isLoading,
      servicesList,
      faSearch,
      searchInput,
      allCategories,
      selected,
      selectedCat,
      searchResult,
      isSearchLoading,
      searchProfile,
      faAngleDown,
      mapCategory,
      filterCategory,
      faAngleRight,
      resetSearch,
      autoCompleteRes
    }
  }
}
</script>
